import jacob from "../Files/officer_pics/officers_2023-2024/jacob.png"
import april from "../Files/officer_pics/officers_2023-2024/april.jpeg"
import ben from "../Files/officer_pics/officers_2023-2024/ben.jpeg"
import brandon from "../Files/officer_pics/officers_2023-2024/brandon.jpg"
import emily from "../Files/officer_pics/officers_2023-2024/emily.jpg"
import victor from "../Files/officer_pics/officers_2023-2024/victor.jpeg"
import vivian from "../Files/officer_pics/officers_2023-2024/vivian.jpeg"
import joy from "../Files/officer_pics/officers_2023-2024/joy.jpg"
import keshav from "../Files/officer_pics/officers_2023-2024/keshav.jpg"
import isabelle from "../Files/officer_pics/officers_2023-2024/isabelle.JPG";
import dustin from "../Files/officer_pics/officers_2023-2024/dustin.jpg"
import gavin from "../Files/officer_pics/officers_2023-2024/gavin.jpeg"
import sophia from "../Files/officer_pics/officers_2023-2024/sophia.jpg"
import anna_nguyen from "../Files/officer_pics/officers_2023-2024/anna.jpg"

//template for buffer spots
// {
//     "image": "",
//     "name": "",
//     "title": "",
//     "college": "",
//     "major": ""3
// },

export default [
  //multi-line strings should be encapuslated with the ` character, same key as the ~ (tilde)
  //hometown is major. we should change that
  {
    image: emily,
    name: "Emily Huang",
    title: "Co-President",
    college: "McMurtry 2024",
    major: "COMP, ECON",
  },
  {
    image: jacob,
    name: "Jacob Pan",
    title: "Co-President",
    college: "Martel 2024",
    major: "COMP",
  },
  {
    image: april,
    name: "April Tong",
    title: "Co-Internal VP",
    college: "Lovett 2026",
    major: "COMP,",
  },
  {
    image: ben,
    name: "Ben Liu",
    title: "Co-Internal VP",
    college: "McMurtry 2026",
    major: "COMP, DSCI",
  },
  {
    image: vivian,
    name: "Vivian Xiao",
    title: "Co-External VP",
    college: "Sid 2025",
    major: "COMP, VADA",
  },
  {
    image: victor,
    name: "Victor Xie",
    title: "Co-External VP",
    college: "Lovett 2025",
    major: "COMP, DSCI",
  },
  {
    image: brandon,
    name: "Brandon Rice",
    title: "Treasurer",
    college: "Jones 2024",
    major: "COMP, BUSI",
  },

  {
    image: keshav,
    name: "Keshav Shah",
    title: "Co-Events Chair",
    college: "Baker 2026",
    major: "COMP",
  },
  {
    image: isabelle,
    name: "Isabelle Goodman",
    title: "Co-Events Chair",
    college: "Will Rice 2025",
    major: "COMP, STAT, DSCI",
  },
  {
    image: dustin,
    name: "Dustin Ho",
    title: "Co-Events Chair",
    college: "Baker 2026",
    major: "COMP",
  },
  {
    image: anna_nguyen,
    name: "Anna Nguyen",
    title: "Webmaster",
    college: "McMurtry 2026",
    major: "COMP, DSCI",
  },
  {
    image: joy,
    name: "Joy Yu",
    title: "Publicist",
    college: "Sid 2025",
    major: "COMP, STAT, BUSI",
  },
  {
    image: sophia,
    name: "Sophia Xing",
    title: "Co-Freshman Rep",
    college: "Hanszen 2027",
    major: "COMP, BUSI",
  },
  {
    image: gavin,
    name: "Gavin Ni",
    title: "Co-Freshman Rep",
    college: "Brown 2027",
    major: "COMP, BUSI",
  }

];
