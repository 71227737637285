export default {

    //Resource page
    "name1": "Resume Reviews",
    "blurb1": `Bring your resumes and get feedback and advice from upper classmen and an invited experienced 
    professional who has extensive experience reviewing and evaluating resumes. We will discuss 
    resume content and formatting tips and provide constructive criticism in an informal setting.  
    A resume review session is usually held during the week before the university career expo.`, 

    "name2": "Puzzle Hunts",
    "blurb2": `Puzzle Hunt is a fun filled event with lots of logic puzzles with different themes and topics! 
    These events range in duration from a couple hours to day-long grinds, and the depth of the puzzles are also 
    varied! If you're at all interested in thinking long and hard about a variety of topics (not just CS!!!) stay 
    tuned for these throughout the semester. Absolutely NO CS experience necessary!!`,

    "name3": "Campus Resources",
    "blurb3": `Rice University itself provides career resources to students; many of these are applicable to CS students.
    • Center for Student Professional Development (CSPD)
    • Rice Career Network`,

    "name4": "Past Experience Panels",
    "blurb4": `In the fall, we generally host two panels for students to learn about various CS opportunities - 
    one for general summer experiences, and one targeted specifically towards underclassmen. At these panels,
    you can hear about other students' experiences pursuing research, working at startups, and various internship programs,
    as well as discuss interview and resume tips.`,

    "name5": "Full-time & Internship Recruiting Events",
    "blurb5": `Also called F.I.R.E., these events bring recruiters and engineers from a variety of companies to present to
    Rice students. Specifics vary company-to-company, but generally you'll get to hear about their
    opportunities, application tips, and culture, plus time for questions. Previous F.I.R.E. events include companies like Uber, 
    Facebook, Stripe, Two Sigma, and more!`,

    //Contact Page
    "nameT": 'Tech Talks and Info Sessions',
    "blurbT": `Tech Talks are a great way to get Rice students interested in what your company does and get in 
    contact with potential interns and employees. If you are interested in hosting one of these, please email us above. 
    Please be ready to provide a preferred date, time, and duration.`,

    "nameS": 'Sponsorship',
    "blurbS": `Putting together HackRice every year is expensive, so we really appreciate all of our sponsors. 
    If you’d like to support HackRice and other club activities financially, we have a variety of sponsorship packages 
    available. Please contact one of our current HackRice Co-Chairs for more information.`,

    //Events Page
    "nameE": 'Social Events',
    "blurbE": `Want to meet other CS students, destress, or just have fun? CS Club hosts a variety of exciting social events
    throughout the academic year. In the fall, make some new friends through our Big-Little Mixer or CS Eats! In the spring,
    we hold the infamous CS Beer Debates, where you can witness your professors enjoy some beer and answer silly questions.
    Also keep an eye out for study breaks, movie nights, and more!`,

    "nameH": 'Hackathons',
    "blurbH": `Rice CS Club hosts a yearly student-led hackathon, HackRice. During HackRice, students meet to code 
    over the span of a weekend to build amazing projects! It’s simple: code, eat, sleep, and see what you come up with! HackRice
    provides lots of snacks, free swag, and catered food to help keep you fueled up. At the end, participants demonstrate their 
    apps to the audience and judges for a chance to win one of many available prizes.`,

}